.collapse-modal {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
.collapse-modal.show {
  height: auto;
}
.activetoggleBtn {
  transform: rotate(180deg);
}

.toggleBtn {
  padding: 0 1rem 0 0;
  transition: 0.3s;
}
