@tailwind utilities;

.nav-item:has(a.active) {
  background-color: var(--text-accent);
  color: #fff;
}

.acc-toggle {
  align-items: flex-start;
  cursor: pointer;
  transition: 0.1s;
}

.acc-header:hover {
  transition: 0.2s;
}

.acc-card:last-child {
  margin-bottom: 0;
}

@layer utilities {
  .acc-mobile-title {
    transition: 0.01s;
    font-size: x-large;
    font-weight: bold;
  }

  .acc-title {
    transition: 0.01s;
    font-size: large;
    font-weight: normal;
  }
}

.acc-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0 2rem;
}
.acc-body div {
  margin: 2rem 0;
  line-height: 1.5rem;
  visibility: visible;
}
.collapse {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
.collapse.show {
  height: auto;
}
