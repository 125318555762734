.nav-item:has(a.active) {
  background-color: var(--text-accent);
  color: #fff;
}

.admin-sidebar .nav-item:has(a.active) {
  background-color: #627480;
  color: #fff;
}

.disabled {
  background-color: #62748063;
  color: rgba(255, 255, 255, 0.348);
}
