.box {
  box-shadow: 0px 2px 8px #00000029;
  border-radius: 1.25rem;
  border: 1px solid white;
  transition: all ease 0.1s;
}

.box img {
  object-fit: contain;
  /* margin: 2.5rem auto 0 auto; */
}

.box:hover {
  background: #eaeaea;
}

.homeLoggedOut {
  background: url('../../assets/images/background-home.svg');
  background-repeat: no-repeat;
  background-size: cover;
  border-bottom: 1px solid #000;
}

.card-euro {
  top: 65px;
  right: 140px;
  z-index: -1;
}

.pos {
  bottom: 30px;
  right: 60px;
}

.euro {
  top: 135px;
  right: 210px;
}

.card {
  bottom: 35px;
  left: 30px;
}

.aqua-box {
  box-shadow: 0px 2px 8px #00000029;
  border-radius: 1.25rem;
}

.box-column {
  min-height: 100vh;
}
