header.with-shadow {
  box-shadow: 0px 2px 8px #00000029;
}

header.with-border {
  border-bottom: 2px solid var(--text-primary);
  border-radius: 30px 30px 0 0;
}

.header--login-button {
  padding: 15px 40px;
  border-radius: 10px;
  transition: background-color 0.2s ease-in;
}

.header--login-button:hover {
  background-color: white;
  color: var(--text-primary);
  border: 1px solid var(--text-primary);
}
