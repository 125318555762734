h3 {
  font-size: 1.6rem;
  font-weight: bold;
}

h4 {
  font-size: 1.2rem;
  font-weight: bold;
}

.accordion-toggle {
  align-items: flex-start;
  cursor: pointer;
  transition: 0.3s;
}
.accordion-toggle.active .accordion-icon {
  transform: rotate(180deg);
}

.accordion-card {
  border: 1px solid #ddd;
}

.accordion-card:last-child {
  margin-bottom: 0;
}
.accordion-title {
  transition: 0.3s;
}

.accordion-icon {
  transition: 0.35s;
}
.accordion-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0 2rem;
}
.accordion-body div {
  margin: 2rem 0;
  line-height: 1.5rem;
  visibility: visible;
}
.collapse {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
.collapse.show {
  height: auto;
}
