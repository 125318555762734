.filterBar {
  box-shadow: 0px 0px 10px #30303029;
}

.filterBar input,
.filterBar select {
  background: #f4f4f4;
  padding: 0.3rem;
  border-radius: 0.375rem;
}

label {
  font-weight: bold;
}
