.historybox {
  box-shadow: 0px 0px 10px #30303029;
  border-radius: 1rem;
}

.historybox .value {
  border-bottom: 1px solid #e5e7eb;
}

.historybox .head {
  border-bottom: 1px solid #f2f2f230;
}

.historybox > :first-child > .head {
  border-top-left-radius: 1rem;
}

.historybox > :first-child > .value {
  border-top-right-radius: 1rem;
}

.historybox > :last-child > .head {
  border-bottom-left-radius: 1rem;
}

.historybox > :last-child > .value {
  border-bottom-right-radius: 1rem;
}
